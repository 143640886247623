import { PropsWithChildren } from "react";
import { QaProps, StyleProps } from "src/models";

import { StackStyled } from "./WidgetBase.styles";

type Props = PropsWithChildren & QaProps & StyleProps;

export const WidgetBase = (props: Props) => {
    return <StackStyled {...props} />;
};
