import { Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { EmptyInboxContent } from "src/components/shared/NoContent/EmptyInboxContent";
import { Switcher } from "src/components/shared/Switcher";

import { CategoriesDropdown, Graph, GraphLegend, GraphWrapperStyled, Widget } from "../shared/components";
import { IconStyled } from "../shared/components/Widget/Widget.styles";
import { WIDGET_VIEW_MODE_OPTIONS } from "../Summary.constants";
import { useSiteEventsWidget } from "./SiteEventsWidget.hooks";

export const SiteEventsWidget = () => {
    const { t } = useTranslation();
    const { viewMode, graphData, isSwitcherVisible, isCategoriesDropdownVisible, initCategories, isLoading, isEmpty, setViewMode, updateActiveCategories } =
        useSiteEventsWidget();

    return (
        <Widget
            data-testid="site-events-widget"
            title={t("insights.summary.siteEventsWidget.title")}
            tooltip={t(`insights.summary.siteEventsWidget.tooltip.${viewMode}`)}
            topRightCornerSection={isSwitcherVisible && <Switcher options={WIDGET_VIEW_MODE_OPTIONS} onChange={setViewMode} />}
            isLoading={isLoading}
            footer={<GraphLegend title={t("insights.summary.siteReportsWidget.graphLegend.title")} data={graphData.data} />}
        >
            {/* NOTE: Something is wrong with Graph layout and it gets outside of the container */}
            <Stack direction="column" flex={1} style={{ maxWidth: "100%" }}>
                {isCategoriesDropdownVisible && (
                    <Stack mt="XXS" justifyContent="flex-end">
                        <CategoriesDropdown initValue={initCategories} onChange={updateActiveCategories} />
                    </Stack>
                )}

                {!isEmpty && (
                    <GraphWrapperStyled mt="0">
                        <Graph data={graphData.data} barSize="sm" isVertical />
                    </GraphWrapperStyled>
                )}

                {isEmpty && (
                    <EmptyInboxContent
                        title={t("common.noDataToDisplay")}
                        subtitle={t("common.adjustTheDateAndFilters")}
                        mt="0"
                        icon={<IconStyled variant="GraphIndicator" />}
                    />
                )}
            </Stack>
        </Widget>
    );
};
