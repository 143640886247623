import { useTranslation } from "react-i18next";
import { ValueTile } from "src/components/ValueTile";
import { StyleProps } from "src/models";

import { SummaryGridLayoutStyled } from "../SummaryGrid/SummaryGrid.styles";
import { usePerformanceIndicatorsWidget } from "./PerformanceIndicatorsWidget.hooks";
import { WidgetStyled } from "./PerformanceIndicatorsWIdget.styles";

type Props = StyleProps;

export const PerformanceIndicatorsWidget = ({ style }: Props) => {
    const { t } = useTranslation();
    const { indicators, isLoading } = usePerformanceIndicatorsWidget();
    return (
        <WidgetStyled
            data-testid="performance-indicators-widget"
            title={t("insights.summary.performanceIndicators.title")}
            headerSeparator={false}
            style={style}
            isLoading={isLoading}
        >
            <SummaryGridLayoutStyled>
                {indicators.map(({ value, prevValue, title }) => (
                    <ValueTile key={title} value={value} previousValue={prevValue} title={title} label={t("common.tours", { count: value })} icon="Route" />
                ))}
            </SummaryGridLayoutStyled>
        </WidgetStyled>
    );
};
